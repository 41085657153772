<template>
  <div class="invoiceCon" @click="clickMask">
    <!-- 包含订单-->
    <div class="statusText">
      <div class="text">
        包含{{ Number(orderInfo.type) == 1 ? orderInfo.totalOrder.length : orderInfo.totalOrder }}个订单,开票金额&nbsp;&nbsp;<span
          style="color:red;"
          >￥{{ orderInfo.count }}元</span
        >
      </div>
    </div>
    <!-- 发票详情 -->
    <p class="title">发票详情</p>
    <van-form @submit="onSubmit">
			<!-- 抬头类型 -->
			<div class="invoiceTitleType">
				<div class="tittleLeft">抬头类型</div>
				<div>
					<van-radio-group
						v-model="invoiceObj.data.headerType"
						direction="horizontal"
						@change="radioChange"
					>
						<van-radio name="0" :checked-color="tool.getThemeList(theme)">个人/事业单位</van-radio>
						<van-radio name="1" :checked-color="tool.getThemeList(theme)">企业</van-radio>
        	</van-radio-group>
				</div>
			</div>
			<!-- 企业 -->
			<template v-if="isEnterprise">
				<!-- 发票抬头 -->
				<div class="inputContainer" style="font-size:14px;">
					<div class="inputBox" style="font-size: 14px;">
						<div class="left" style="margin-right: 12px;">
							<span>发票抬头</span>
						</div>
						<div class="right">
							<input
								v-model="invoiceObj.data.buyerName"
								type="text"
								class="textStyle"
								:class="{textError:isinvoiceTitleEmpty}"
								placeholder="请填写需要开具发票的企业名称"
								:maxlength="80"
								@input="handleQuery(1)"
							/>
							<!-- <p v-if="isinvoiceTitleEmpty" class="errmessage">请填写需要开具发票的企业名称</p> -->
							<!-- <ul v-if="isInvoiceTitle" class="optionList" :class="{optionEmpty: !Boolean(results.length),oneNumList:results.length == 1,twoNumList:results.length == 2}">
								<template v-if="results.length > 0 ">
									<li
										v-for="(item, index) in results"
										:key="index"
										v-html="item.name"
										@click="getText(item)"
									>
									</li>
								</template>
								<template v-else>
									<li>
										暂无数据
									</li>
								</template>
							</ul> -->
						</div>
					</div>
				</div>
				<p class="underline" style="margin:0px 16px;"></p>
				<!-- 税号 -->
				<div class="inputContainer" style="font-size:14px;">
					<div class="inputTaxNumberBox" style="font-size: 14px;">
						<div class="left" style="margin-right: 12px;">
							<span>税号</span>
						</div>
						<div class="right">
							<input
								v-model="invoiceObj.data.buyerTaxNo"
								type="text"
								class="textStyle"
								:class="{textError:isTaxNumberEmpty}"
								placeholder="请填写纳税人识别号"
								@input="handleQuery(2)"
							/>
							<!-- <p v-if="isTaxNumberEmpty" class="errmessage">请填写纳税人识别号</p> -->
							<!-- <ul v-if="isTaxNumber" class="optionTaxNumberList" :class="{optionEmpty: !Boolean(results.length),oneNumList:results.length == 1,twoNumList:results.length == 2}">
								<template v-if="results.length > 0 ">
									<li
										v-for="(item, index) in results"
										:key="index"
										v-html="item.name"
										@click="getText(item)"
									>
									</li>
								</template>
								<template v-else>
									<li>
										暂无数据
									</li>
								</template>
							</ul> -->
						</div>
					</div>
				</div>
				<p class="underline" style="margin:0px 16px;"></p>
			</template>
			<!-- 个人 -->
			<template v-else>
				<van-field
					v-model="invoiceObj.data.buyerName"
					:maxlength="80"
					input-align="left"
					label="发票抬头"
					placeholder="填写需要开具发票的企业名称"
					:rules="[{ validator : validator3, message: '请填写需要开具发票的企业名称' }]"
					required
				/>
			</template>
      <van-field
        v-model="invoiceObj.data.depositBank"
        input-align="left"
        label="开户银行"
        placeholder="选填"
				:maxlength="50"
      />
      <van-field
				type="number"
        v-model="invoiceObj.data.bankAccount"
        input-align="left"
        label="银行账号"
        placeholder="选填"
				:maxlength="50"
      />
      <van-field
        v-model="invoiceObj.data.address"
        input-align="left"
        :label="isEnterprise ? '企业地址' : '注册地址'"
        placeholder="选填"
				:maxlength="80"
      />
      <van-field
				type="number"
        v-model="invoiceObj.data.telephone"
        input-align="left"
        :label="isEnterprise ? '企业电话' : '注册电话'"
        placeholder="选填"
				:maxlength="20"
      />
    	<!-- 接收方式 -->
    	<p class="title">接收方式</p>
			<van-field
				v-model="invoiceObj.data.buyerEmail"
				input-align="left"
				label="电子邮箱"
				placeholder="用于向您发送电子发票"
				:rules="[{ validator, message: '请输入正确的邮箱,用于向您发送电子发票' }]"
				required
			/>
			<van-field
				v-model="invoiceObj.data.buyerPhone"
				input-align="left"
				label="手机号码"
				placeholder="用于向您发送电子发票"
				:rules="[{ validator : validator2, message: '请输入正确的手机号，用于向您发送电子发票' }]"
				required
			/>
			<div class="submitbox">
				<van-button class="submitPage" native-type="submit" @click="clickHandle">提交</van-button>
			</div>
		</van-form>
		<!-- 确认发票信息 -->
		<van-action-sheet v-model:show="invoiceShow" title="开票信息确认" :round="false">
			<div class="invoice_con">
				<p>主要信息</p>
				<div class="invoice_check">
					<van-cell-group :border="false">
						<van-cell title="发票抬头" :value="invoiceObj.data.buyerName" :border="false"/>
						<van-cell v-if="isEnterprise" title="税号" :value="invoiceObj.data.buyerTaxNo" :border="false"/>
						<van-cell title="电子邮箱" :value="invoiceObj.data.buyerEmail" :border="false"/>
						<van-cell title="手机号码" :value="invoiceObj.data.buyerPhone" :border="false"/>
					</van-cell-group>					
				</div>
				<p>注意事项</p>
				<span class="tips">请务必确认填写的开票信息正确，信息错误将会无法开票且无法修改</span>
				<div class="submitbox">
					<van-button class="submitPage" @click="submitHandle">确认开票</van-button>
				</div>
			</div>
		</van-action-sheet>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted } from "vue";
import { useRoute } from "vue-router";
import { isEmail, isMobile } from "../../utils/validate";
import { getStore } from "@/utils/store";
import { emptyObject } from "@/utils/utils";
export default {
  setup() {
    const state = reactive({
			radioType: '1',
			theme: "", //主题名称
			invoiceShow: false, //确认发票信息面板
			isEnterprise: false,
      invoiceObj: {
        data:{
          invoiceTypeCode: '',
          headerType: "0",
          buyerName: "",
          buyerTaxNo: "",
          depositBank: "",
          bankAccount: "",
          address: "",
          telephone: "",
          buyerEmail: "",
          buyerPhone: ""
        }
      },
			orderInfo:{},
			InvoiceTitle:[],
			results: [],
			timer: null,
			isInvoiceTitle:false,
			isTaxNumber:false,
			isinvoiceTitleEmpty:false,
			isTaxNumberEmpty:false
    });
		// 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router : router, $http : http } = ctx;
		//调用useRoute,获取页面携带的参数
    const route = useRoute();
		Object.assign(state.orderInfo , route.query)
    const methods = {
			// 定时器清除
			clearTimer() {
				if (state.timer) {
					clearTimeout(state.timer)
				}
			},
			// 云抬头查询=>节流搜索
			handleQuery(type) {
				return
				//王路排查该接口无用，暂关掉该功能
				ctx.clearTimer()
				ctx.timer = setTimeout(async() => {
						state.InvoiceTitle =  await ctx.getCompanySearch() 
						ctx.changeColor(state.InvoiceTitle,type)
				}, 1000)
			},
			// 关键字颜色
			changeColor(resultsList,type) {
				// type 1-发票抬头 2-税号
				if(type == 1) {
					state.isInvoiceTitle = true;
					state.isTaxNumber = false;
				}else{
					state.isTaxNumber = true;
					state.isInvoiceTitle = false
				} 
				resultsList.map((item, index) => {
					if (state.invoiceObj.data.buyerName && state.invoiceObj.data.buyerName.length > 0 || state.invoiceObj.data.buyerTaxNo && state.invoiceObj.data.buyerTaxNo.length > 0) {
						// 匹配关键字正则
						let replaceReg = new RegExp(state.invoiceObj.data.buyerName, 'g')
						// 高亮替换v-html值
						let replaceInvoiceTitle = `<span style="color:${type == 1 ? tool.getThemeList(store.state.common.theme) : '#fff'}">` + state.invoiceObj.data.buyerName + `</span>`
						let replaceTaxNumber = `</br>` + `<span style="color:${type == 2 ? tool.getThemeList(store.state.common.theme) : '#fff'}">` + item.taxId + `</span>`
						// 输入框的值
						resultsList[index].inputText = item.name.replace(
							replaceReg,
							replaceInvoiceTitle
						)
						// 节点选项的值
						resultsList[index].name = item.name.replace(
							replaceReg,
							replaceInvoiceTitle
						) + replaceTaxNumber
					}
				})
				state.results = []
				state.results = resultsList
			},
			// 把带有html 的文本变成text文本
			repalceHtmlToText(str) {
				str = str.replace(/<\/?.+?>/g, '') //标签解析
				str = str.replace(/&nbsp;/g, '') //去除空格
				return str
			},
			// 得到转化后的文本赋值给文本输入框
			getText(item) {
				Object.assign(state.invoiceObj.data, {
					depositBank: item.bank,
					bankAccount: item.bankAccount,
					address: item.location,
					telephone: item.mobilePhone, 
					buyerName: ctx.repalceHtmlToText(item.inputText),// 发票抬头
					buyerTaxNo: item.taxId // 发票税号
      	});
				state.isInvoiceTitle = false
				state.isTaxNumber = false
				state.isinvoiceTitleEmpty = false
				state.isTaxNumberEmpty = false
			},
			// 点击空白处关闭云抬头列表框
			clickMask() {
				state.isInvoiceTitle = false
				state.isTaxNumber = false
      },
			// 校验器=>电子邮箱
			validator(val){
				if(!Boolean(val) || !isEmail(val)){
					return false
				}
				return true
			},
			// 校验器=>手机号码
			validator2(val){
				if(!Boolean(val) || !isMobile(val)){
					return false
				}
				return true
			},
			// 校验器=>发票抬头
			validator3(val){
				if(!Boolean(val)){
					return false
				}
				return true
			},
			// 确认提交句柄
			submitHandle(){
				/**
         * 银行地址及账户 - buyerBankAccount 、企业地址及电话 - buyerAddressPhone
         * 非空时 拼接参数
         */
        let buyerBankAccount = '';
        let buyerAddressPhone = '';

        if(!state.invoiceObj.data.depositBank && state.invoiceObj.data.bankAccount){
          buyerBankAccount = state.invoiceObj.data.bankAccount
        }
        if(state.invoiceObj.data.depositBank && !state.invoiceObj.data.bankAccount){
          buyerBankAccount = state.invoiceObj.data.depositBank
        }
        if(state.invoiceObj.data.depositBank && state.invoiceObj.data.bankAccount){
          buyerBankAccount = state.invoiceObj.data.depositBank + ' ' + state.invoiceObj.data.bankAccount
        }
        
        if(!state.invoiceObj.data.address && state.invoiceObj.data.telephone){
          buyerAddressPhone = state.invoiceObj.data.telephone
        }
        if(state.invoiceObj.data.address && !state.invoiceObj.data.telephone){
          buyerAddressPhone = state.invoiceObj.data.address
        }
        if(state.invoiceObj.data.address && state.invoiceObj.data.telephone){
          buyerAddressPhone = state.invoiceObj.data.address + ' ' + state.invoiceObj.data.telephone
        }

        // 申请发票
				http.post(`/outputInvoice/issue`,
					{	
            ...state.invoiceObj.data,
            invoiceType: 0,
            userType: 1,
            buyerBankAccount,
            buyerAddressPhone,
            infoList: getStore({ name: `InvoiceListData` }) || [],
            invoiceTotalPrice: state.orderInfo.count,
            sim: store.state.user.userInfo.sim,
						iccid:store.state.user.userInfo.iccid
					}
				).then(({ data: res })=>{
					if(res.code == 0){
						router.push({
							name:"submit-result",
							query:{
								type : 1,
							}
						})
						return
					}
					router.push({
						name:"submit-result",
						query:{
							type : 0,
							msg: res.msg
						}
					})
				})
			},
			// 表单校验
      onSubmit() {
				// 企业 需校验发票抬头 税号
				if(state.isEnterprise && (!Boolean(state.invoiceObj.data.buyerName) || !Boolean(state.invoiceObj.data.buyerTaxNo))) return state.invoiceShow = false 
				state.invoiceShow = true
			},
			// 发票抬头、税号输入框的错误校验=>企业
			clickHandle(){
				if(!Boolean(state.invoiceObj.data.buyerName)) {
					state.isinvoiceTitleEmpty = true
				}else{
					state.isinvoiceTitleEmpty = false
				} 
				if(!Boolean(state.invoiceObj.data.buyerTaxNo)) {
					state.isTaxNumberEmpty = true
				}else{
					state.isTaxNumberEmpty = false
				} 
			},
			// 获取云抬头
			getCompanySearch() {
				return new Promise(resolve => {
					http
						.get(`/invoice/companySearch`, {
							params: {
								companyName: state.invoiceObj.data.buyerName,
								taxId: state.invoiceObj.data.buyerTaxNo
							}
						})
						.then(({ data: res }) => {
							if (res.code == 0) {
								resolve(res.resultList || []);
							} else {
								tool.toast(
									{
										msg: res.msg,
										duration: 1500
									},
									() => {}
                );
							}
						});
				});
    	},
			/**抬头类型句柄
			 * @param {val} 1-个人/事业单位  2-企业
			 */
			radioChange(val) {
				// 驳回 重新提交的不清key的value值
				if(Number(route.query.status) != 3){
					emptyObject(state.invoiceObj.data,
						(obj, key)=>{
								if(key != "headerType" && key != "buyerEmail" && key != "buyerPhone" && key !== 'id'){ 
									obj[key] = ''
								}
							}
						)
				}
				if (Number(val) == 0) return state.isEnterprise = false;
				state.isEnterprise = true;
			}
    };
		onMounted(()=>{
			//获取主题名称
      state.theme = store.state.common.theme;
			// 发票驳回
			if(Number(route.query.status) == 3){
				//获取发票驳回数据
				state.invoiceObj = getStore({ name: `rejectInvoiceData` })
				// 抬头类型
				state.invoiceObj.data.headerType = String(state.invoiceObj.type)
			}
		})
    return {
      ...toRefs(state),
      ...methods,
			tool
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.invoiceCon {
  height: 100vh;
  margin: 0 auto;
	z-index: 2;
	overflow-x: hidden;
  background: #ffffff;
  .statusText {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-top: 10px;
    .text {
      padding-left: 6px;
    }
  }
  .title {
    margin: 30px 0px;
    padding-left: 20px;
  }
	.invoiceTitleType{
		display: flex;
		justify-content: space-between;
		.tittleLeft{
			padding-left: 20px;
		}
	}
  .submitbox {
    width: 690px;
    margin: 30px auto;
    .submitPage {
      width: 100%;
			color: #fff;
			@include background_color("background_color1");
    }
  }
}
.invoice_con{
	padding: 10px 30px 10px;
	p{
		font-weight: bold;
	}
	.tips{
		color: red;
		font-size: 16px;
	}
}
.inputContainer{
	padding: 2.133vw 0px 1.333vw 3.467vw;
	.inputBox,.inputTaxNumberBox{
		display: flex;
		color: #646566;
		.left{
			-webkit-box-flex: 0;
			-webkit-flex: none;
			flex: none;
			box-sizing: border-box;
			width: 6.2em;
			color: #646566;
			text-align: left;
			word-wrap: break-word;
		}
	}
	.inputBox::before{
		position: absolute;
		left: 14px;
		color: #ee0a24;
		content: '*';
	}
	.inputTaxNumberBox::before{
		position: absolute;
		left: 14px;
		color: #ee0a24;
		content: '*';
	}
	.right{
		position: relative;
		width: 84%;
		input{
			display: block;
			box-sizing: border-box;
			width: 100%;
			min-width: 0;
			margin: 0;
			padding: 0;
			color: #323233;
			line-height: inherit;
			text-align: left;
			background-color: transparent;
			border: 0;
			resize: none;
		}
		.textStyle::placeholder{
			color: #C8C9CC;
		}
		.textError::placeholder{
			color: red;
		}
		.errmessage{
			color: red;
		}
		.optionList,.optionTaxNumberList,.optionEmpty,.oneNumList,.twoNumList{
			background: rgba(48, 46, 46, 0.7);
			opacity: 0.8;
			width: 94%;
			height: 29.267vw;
			overflow-y: auto;
			z-index: 5;
			position: absolute;
			top: 6.5vw;
			li {
			color: #fff;
			white-space: nowrap;
			overflow-x: auto;
			}
		}
		.optionEmpty{
			height: 40px;
			text-align: center;
		}
		.oneNumList{
			height: 10.267vw;
		}
		.twoNumList{
			height: 19.267vw;
		}
	}
}
.underline{
	border-bottom: 1px solid #ebedf0;
}
.van-cell {
	padding: 16px 0px 10px 26px;
}
</style>
